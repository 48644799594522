<template>
  <div class="topbar">
    <img src="@/assets/logo.svg" />
    <div class="progress-wrapper">
      <div class="progress-bar">
        <div :style="{ width: progress }" class="inner-bar"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  computed: {
    progress() {
      return (
        (this.$store.state.currentStep / this.$store.state.steps) * 100 + "%"
      );
    },
  },
};
</script>

<style scoped lang="scss">
.topbar {
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  .progress-wrapper {
    display: block;
    width: 100%;
    padding-left: 35px;
    margin-top: 6px;
    .progress-bar {
      position: relative;
      overflow: hidden;
      height: 5px;
      background-color: #d7d7df;
      border-radius: 5px;

      .inner-bar {
        position: absolute;
        background-color: #7fb413;
        left: 0;
        height: 100%;
        border-radius: 5px;
        transition: width 400ms 200ms;
      }
    }
  }
}
</style>
