<template>
  <div class="wrapper">
    <h1>What kind of challenges do you face?</h1>
    <div class="input-wrapper" v-for="(anwser, index) in anwsers" :key="index">
      <input
        type="checkbox"
        :id="anwser.key"
        :value="anwser.key"
        v-model="challenges"
      />
      <label :for="anwser.key">{{ anwser.name }}</label>
    </div>
    <div class="btn-wrapper">
      <button @click="next()" class="btn btn-next" :disabled="!isValid">
        Next
      </button>
      <button @click="skip()" class="btn btn-skip">Skip</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      anwsers: [
        {
          key: "lacking_results",
          name: "Lacking results",
        },
        {
          key: "agents_efficiency",
          name: "Agents efficiency",
        },
        {
          key: "complex_topics",
          name: "Complex topics",
        },
        {
          key: "customer_satisfaction",
          name: "Customer satisfaction",
        },
      ],
    };
  },
  methods: {
    next() {
      this.$store.state.skippedFirstStep = false;
      this.$store.dispatch("nextStep", 2);
    },
    skip() {
      this.$store.state.skippedFirstStep = true;
      this.$store.dispatch("nextStep", 3);
    },
  },
  computed: {
    challenges: {
      get() {
        return this.$store.state.userInput.challenges;
      },
      set(val) {
        this.$store.state.userInput.challenges = val;
      },
    },

    isValid() {
      if (this.challenges.length > 0) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
h1 {
  margin-bottom: 45px;
}
</style>
