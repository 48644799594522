<template>
  <div class="wrapper">
    <BackButton />

    <h1>
      To what extent do you consider your organization to be driven by
      innovation?
    </h1>
    <div class="input-wrapper" v-for="(anwser, index) in anwsers" :key="index">
      <input
        type="radio"
        :id="anwser.key"
        :value="anwser.key"
        v-model="drivenByInnovation"
      />
      <label :for="anwser.key">{{ anwser.name }}</label>
    </div>
    <div class="btn-wrapper">
      <button
        @click="$store.dispatch('nextStep', 4)"
        class="btn btn-next"
        :disabled="!isValid"
      >
        Next
      </button>
    </div>
  </div>
</template>
<script setup>
import BackButton from "@/components/BackButton.vue";
</script>

<script>
export default {
  data() {
    return {
      anwsers: [
        {
          key: "highly",
          name: "Highly",
        },
        {
          key: "moderately",
          name: "Moderately",
        },
        {
          key: "somewhat",
          name: "Somewhat",
        },
        {
          key: "not_very",
          name: "Not very",
        },
      ],
    };
  },
  computed: {
    drivenByInnovation: {
      get() {
        return this.$store.state.userInput.driven_by_innovation;
      },
      set(val) {
        this.$store.state.userInput.driven_by_innovation = val;
      },
    },

    isValid() {
      if (this.drivenByInnovation !== "") {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
h1 {
  margin-bottom: 45px;
}
</style>
