<template>
  <TopBar />
  <transition
    mode="out-in"
    :enter-active-class="enterActiveClass"
    :leave-active-class="leaveActiveClass"
  >
    <Step1 v-if="currentStep === 1" />
    <Step2 v-else-if="currentStep === 2" />
    <Step3 v-else-if="currentStep === 3" />
    <Step4 v-else-if="currentStep === 4" />
    <Step5 v-else-if="currentStep === 5" />
    <Step6 v-else-if="currentStep === 6" />
  </transition>
</template>

<script setup>
import "animate.css";
import TopBar from "@/components/TopBar.vue";
import Step1 from "@/components/Step1.vue";
import Step2 from "@/components/Step2.vue";
import Step3 from "@/components/Step3.vue";
import Step4 from "@/components/Step4.vue";
import Step5 from "@/components/Step5.vue";
import Step6 from "@/components/Step6.vue";
</script>

<script>
export default {
  mounted() {},
  computed: {
    currentStep() {
      return this.$store.state.currentStep;
    },
    enterActiveClass() {
      return this.$store.state.enterActiveClass;
    },
    leaveActiveClass() {
      return this.$store.state.leaveActiveClass;
    },
  },
  methods: {},
};
</script>

<style lang="scss">
@import url("https://campaigns.mobilewater.nl/cdn/css/spectre@0.5.9/spectre.min.css");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;800&display=swap");

:root {
  --animate-duration: 600ms;
  --maz-color-primary: #88d100;
}

html {
  font-size: 14px;
}

body {
  font-family: "Plus Jakarta Sans", sans-serif;
  // position: fixed;
  background: #ececec;
  color: #131314;
  font-size: 14px;
  font-weight: 500;
  // height: 100vh;
  // width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    background: #fff;
    padding: 35px;
    color: #131314;
    font-weight: 500;
    width: 100%;
    max-width: 450px;
    min-height: 100vh;
    overflow-x: hidden;
    height: 100%;
    &::-webkit-scrollbar {
      display: none;
    }

    h1 {
      font-weight: 800;
      font-size: 27px;
      text-align: left;
      margin-bottom: 25px;
    }

    h2 {
      font-size: 18px;
      font-weight: 800;
    }

    p {
      line-height: 21px;
    }

    ul {
      margin-left: 0;
    }

    .btn {
      border-radius: 50px;
      font-size: 16px;
      color: #131314;
      border: none;
      background-color: #98cf27;
      padding: 27px;
      line-height: 0;

      &:disabled {
        color: #84848e;
        background-color: #d7d7df;
      }

      &.btn-next {
        position: relative;
        padding-right: 100px;
        &:after {
          content: "";
          position: absolute;
          right: 25px;
          top: 50%;
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.5" height="16.121"><g data-name="arrow-right (9)" fill="none" stroke="%23131314" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path data-name="Line 271" d="M.75 8.061h14"/><path data-name="Path 2266" d="m7.75 1.061 7 7-7 7"/></g></svg>');
          width: 16px;
          height: 16px;
          transform: translateY(-50%);
        }
      }

      &.btn-skip {
        background: 0;
        padding: 0;
        border-radius: 0;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
    .input-wrapper {
      margin-bottom: 7px;
      label {
        position: relative;
        display: block;
        padding: 16px;
        border-radius: 50px;
        text-align: center;
        border: solid 1px #a2a2ae;
        transition: background-color 200ms, border-color 200ms;

        &:after {
          opacity: 0;
          content: "";
          width: 28px;
          height: 28px;
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28"><g data-name="Group 4582" fill="none" stroke="%23131314"><g data-name="Ellipse 400"><circle cx="14" cy="14" r="14" stroke="none"/><circle cx="14" cy="14" r="13.5"/></g><path data-name="check (35)" d="m19.823 9.496-8.007 8.008-3.64-3.64" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></g></svg>');
          transition: opacity 200ms;
        }
      }
      input {
        display: none;

        &:checked + label {
          background-color: #f1f1f2;
          border-color: #f1f1f2;

          &::after {
            opacity: 1;
          }
        }
      }
    }
    .btn-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      align-items: flex-end;

      .btn-skip {
        margin-top: 65px;
        align-self: center;
      }
    }
    .form-wrapper {
      .m-input-wrapper {
        border-radius: 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom-width: 2px;
      }
    }
  }
}
</style>
