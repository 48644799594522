<template>
  <div class="wrapper">
    <h1>Thanks!</h1>
    <p>We will make sure to provide you with a demo within 24 hours.</p>

    <h2>Want to know more?</h2>
    <p>
      Please go to our website to get your first impression of the possibilities
      of Liveshare.
    </p>
    <div class="btn-wrapper">
      <a href="https://www.mobilewater.nl/liveshare" class="btn btn-next">
        Website
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
};
</script>

<style scoped lang="scss"></style>
