<template>
  <div class="wrapper">
    <BackButton />
    <template v-if="challengeType === 'lacking_results'">
      <h1>The pursuit of KPI's, an endless uphill struggle</h1>
      <p>
        Conversion rates remain elusive, and CSAT scores disappoint. Your
        dedication shines despite these formidable challenges.
      </p>
      <h2>Conversion and CSAT</h2>
      <p>
        Two factors which, with nowadays toolsets, are limited to be impacted.
        Leaving the full responsibility for agents to do the work. But what if
        there's another way?
      </p>
    </template>
    <template v-if="challengeType === 'agents_efficiency'">
      <h1>It can be challenging to keep efficiency high</h1>
      <p>
        More than 67% of call centers struggle with handling a large volume of
        incoming and outgoing calls. Managing this sheer quantity of calls while
        maintaining quality can be a challenge.
      </p>
      <h2>Training and onboarding</h2>
      <p>
        Agents require extensive training to handle customer inquiries
        effectively. The time it takes to onboard new agents and keep existing
        agents up-to-date can be a drain on efficiency.
      </p>
    </template>
    <template v-if="challengeType === 'complex_topics'">
      <h1>Complex topics & explaining</h1>
      <p>
        How many times do you have to repeat yourself or send more information
        via e-mail? The answer is: Too many times to keep having fun in your
        job. What if you could show, so you don't have to explain?
      </p>
      <h2>Matching and understanding</h2>
      <p>
        Do you understand me? "No" is a common answer after a 5 minute Phone
        call. Not with Liveshare.
      </p>
    </template>
    <template v-if="challengeType === 'customer_satisfaction'">
      <h1>The pursuit of KPI's, an endless uphill struggle</h1>
      <p>
        Conversion rates remain elusive, and CSAT scores disappoint. Your
        dedication shines despite these formidable challenges.
      </p>
      <h2>Conversion and CSAT</h2>
      <p>
        Two factors which, with nowadays toolsets, are limited to be impacted.
        Leaving the full responsibility for agents to do the work. But what if
        there's another way?
      </p>
    </template>
    <div class="btn-wrapper">
      <button @click="$store.dispatch('nextStep', 3)" class="btn btn-next">
        Next
      </button>
    </div>
  </div>
</template>
<script setup>
import BackButton from "@/components/BackButton.vue";
</script>

<script>
export default {
  data() {
    return {
      content: {},
    };
  },
  computed: {
    challengeType() {
      return this.$store.state.userInput.challenges[0];
    },
  },
};
</script>

<style scoped lang="scss"></style>
