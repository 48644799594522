<template>
  <div class="wrapper">
    <BackButton />
    <h1>The best way is to experience it yourself</h1>
    <p>
      As we say, seeing is believing. So to give you a good impression of
      Liveshare, we would like to show it to you by giving you a quick demo
      call.
    </p>

    <div class="form-wrapper">
      <MazInput class="custom-input" v-model="name" label="Your name" />
      <MazPhoneNumberInput
        class="phone-number"
        v-model="phoneNumber"
        :preferred-countries="['NL', 'BE', 'FR', 'DE', 'US', 'GB']"
        show-code-on-list
        @update="results = $event"
        :success="results?.isValid"
      />
    </div>

    <div class="btn-wrapper">
      <button @click="submit()" class="btn btn-next" :disabled="!isValid">
        Next
      </button>
    </div>
  </div>
</template>
<script setup>
import BackButton from "@/components/BackButton.vue";
import "maz-ui/css/main.css";
import MazInput from "maz-ui/components/MazInput";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
</script>

<script>
export default {
  data() {
    return {
      results: "",
    };
  },
  methods: {
    submit() {
      this.$store
        .dispatch("submitForm")
        // .then(() => {})
        .catch((response) => {
          console.log(response);
        });
      this.$store.dispatch("nextStep", 6);
    },
  },
  computed: {
    isValid() {
      if (this.results.isValid && this.name !== "") {
        return true;
      }
      return false;
    },
    name: {
      get() {
        return this.$store.state.userInput.name;
      },
      set(val) {
        this.$store.state.userInput.name = val;
      },
    },
    phoneNumber: {
      get() {
        return this.$store.state.userInput.phone_number;
      },
      set(val) {
        this.$store.state.userInput.phone_number = val;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.custom-input {
  // height: 4rem;
  margin-bottom: 20px;
}
</style>
