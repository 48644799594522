import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    currentStep: 1,
    steps: 6,
    skippedFirstStep: false,
    enterActiveClass: "animate__animated animate__fadeInRight",
    leaveActiveClass: "animate__animated animate__fadeOutLeft",
    userInput: {
      challenges: [],
      driven_by_innovation: "",
      name: "",
      phone_number: "",
    },
  },
  getters: {},
  mutations: {},
  actions: {
    nextStep({ state }, step) {
      state.currentStep = step;
      state.enterActiveClass = "animate__animated animate__fadeInRight";
      state.leaveActiveClass = "animate__animated animate__fadeOutLeft";
      state.currentStep = step;
    },

    prevStep({ state }, step) {
      state.enterActiveClass = "animate__animated animate__fadeInLeft";
      state.leaveActiveClass = "animate__animated animate__fadeOutRight";
      state.currentStep = step;
    },

    submitForm({ state }) {
      const endpoint =
        "https://api.hsforms.com/submissions/v3/integration/submit/6892864/b142578a-0c01-4365-98c1-41b59ab94ed2";

      const data = {
        fields: [
          {
            name: "phone",
            value: state.userInput.phone_number,
          },
          {
            name: "firstname",
            value: state.userInput.name,
          },
          {
            name: "data",
            value:
              "challenges: " +
              state.userInput.challenges +
              "\n" +
              "driven_by_innovation: " +
              state.userInput.driven_by_innovation +
              "\n",
          },
        ],
        context: {
          pageUri: window.location.href,
          pageName: "Liveshare assessment",
        },
      };

      return new Promise((res, rej) => {
        axios
          .post(endpoint, data)
          .then(function (response) {
            res(response);
          })
          .catch(function (error) {
            console.log(error.response);
            rej(error);
          });
      });
    },
  },
  modules: {},
});
