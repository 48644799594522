<template>
  <div class="back-button-wrapper">
    <img
      @click="prev()"
      class="back-arrow"
      src="@/assets/arrow-left-circle.svg"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    prev() {
      const skippedFirstStep = this.$store.state.skippedFirstStep;
      const currentStep = this.$store.state.currentStep;
      let prevStep = this.$store.state.currentStep - 1;

      if (currentStep == 3 && skippedFirstStep) {
        prevStep = 1;
      }
      this.$store.dispatch("prevStep", prevStep);
    },
  },
};
</script>

<style scoped lang="scss">
.back-button-wrapper {
  margin-bottom: 20px;
}
</style>
