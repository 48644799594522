<template>
  <div class="wrapper">
    <BackButton />
    <h1>Start small, think big</h1>
    <p>
      Starting small provides a valuable learning experience. Organisations such
      as Vodafone (telecom) and Vattenfall (energy) are now using Liveshare to:
    </p>
    <ul>
      <li>Improve their NPS</li>
      <li>Increase conversion</li>
      <li>Quickly train new agents</li>
    </ul>
    <h2>New agents</h2>
    <p>
      You might think that adding a new tool would result in more training time
      for new agents. But actually, we experienced that it reduces time due to
      an increase in confidence that Liveshare gives new agents.
    </p>
    <div class="btn-wrapper">
      <button @click="$store.dispatch('nextStep', 5)" class="btn btn-next">
        Next
      </button>
    </div>
  </div>
</template>
<script setup>
import BackButton from "@/components/BackButton.vue";
</script>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
};
</script>

<style scoped lang="scss"></style>
